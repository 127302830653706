*,
*::before,
*::after {
  box-sizing: border-box; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important;
}

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }
.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }
.mb-6,
.my-6 {
  margin-bottom: 10rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

h1, .h1 {
  font-weight: 600;
  font-size: 2.5rem; }

h2, .h2 {
  font-weight: 600;
  font-size: 2rem; }

h3, .h3 {
  font-weight: 600;
  font-size: 1.75rem; }

h4, .h4 {
  font-weight: 600;
  font-size: 1.5rem; }

h5, .h5 {
  font-weight: 600;
  font-size: 1.25rem; }

h6, .h6 {
  font-weight: 600;
  font-size: 1rem; }
.div_btn:active {
  opacity: 0.5;
}
.btn {
  text-transform: none;
  overflow: visible;
  border-radius: 3px;
  font-size: 13px;
  outline: none !important;
  transition: all 0.1s;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 1rem 3rem;
  font-size: 1rem;
  line-height: 1.52857;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-pill-right, .btn-oval {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.btn-pill-left, .btn-oval {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.btn.btn-warning {
  color: #fff;
}

.btn.btn-outline-warning:hover,
.btn.btn-outline-warning:focus,
.btn.btn-outline-warning:active {
  color: #fff;
}

.btn-secondary,
.calendar-app .fc-button {
  background-color: #fff;
  border-color: #eaeaea;
  color: #333;
}

.btn-secondary:hover,
.calendar-app .fc-button:hover {
  background-color: #f5f5f5;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.calendar-app .fc-button:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.calendar-app .fc-button:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle,
.calendar-app .show>.dropdown-toggle.fc-button {
  background-color: #f5f5f5;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-outline-secondary {
  border-color: #000000 !important;
  color: #333;
}

.btn-outline-secondary:hover {
  color: #333;
  background-color: #f5f5f5 !important;
}

.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 234, 234, 0.5);
}
.btn-yellow {
  color: #0E1D11;
  background-color: #F8E100;
  border-color: #F8E100; }
  .btn-yellow:hover {
    color: #0E1D11;
    background-color: #ead302;
    border-color: #ebd402; }
  .btn-yellow:focus, .btn-yellow.focus {
    box-shadow: 0 0 0 #000; }
  .btn-yellow.disabled, .btn-yellow:disabled {
    color: #0E1D11;
    background-color: #d8c200;
    border-color: #e1ca01; }
  .btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-yellow.dropdown-toggle {
    color: #0E1D11;
    background-color: #e7d002;
    border-color: #e9d205; }
    .btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-yellow.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }

.btn-danger {
  color: #fff;
  background-color: #C50018;
  border-color: #C50018;
}

.btn-danger:hover {
  color: #fff;
  background-color: #C50018;
  border-color: #C50018;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 #000;
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #C50018;
  border-color: #C50018;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #C50018;
  border-color: #C50018;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-gray {
  font-weight: 300;
  color: #0E1D11;
  background-color: #F2F2F2;
  border-color: #F2F2F2; }
  .btn-gray:hover {
    color: #0E1D11;
    background-color: #dcdcdc;
    border-color: #d0d0d0; }
  .btn-gray:focus, .btn-gray.focus {
    box-shadow: 0 0 0 #000; }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #0E1D11;
    background-color: #cecece;
    border-color: #F2F2F2; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #0E1D11;
    background-color: #F2F2F2;
    border-color: #F2F2F2; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.625rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.375rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.7125rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
 }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

.btn-black {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-black:hover {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.btn-black:focus,
.btn-black.focus {
  box-shadow: 0 0 0 #000;
}

.btn-black.disabled,
.btn-black:disabled {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled).active,
.show>.btn-black.dropdown-toggle {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.btn-black:not(:disabled):not(.disabled):active:focus,
.btn-black:not(:disabled):not(.disabled).active:focus,
.show>.btn-black.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-gray-dark {
  color: #AEAEAE;
  background-color: #E9E9E9;
  border-color: #E9E9E9;
}

.btn-gray-dark:hover {
  color: #919191;
  background-color: #ccc8c8;
  border-color: #ccc8c8;
}

.btn-gray-dark:focus,
.btn-gray-dark.focus {
  box-shadow: 0 0 0 #000;
}

.btn-gray-dark.disabled,
.btn-gray-dark:disabled {
  color: #fff;
  background-color: #c0bebe;
  border-color: #c0bebe;
}

.btn-gray-dark:not(:disabled):not(.disabled):active,
.btn-gray-dark:not(:disabled):not(.disabled).active,
.show>.btn-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #c0bebe;
  border-color: #c0bebe;
}

.btn-gray-dark:not(:disabled):not(.disabled):active:focus,
.btn-gray-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-gray-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}



.border {
  border: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #5d9cec !important; }

.border-secondary {
  border-color: #fff !important; }

.border-success {
  border-color: #27c24c !important; }

.border-info {
  border-color: #23b7e5 !important; }

.border-warning {
  border-color: #ff902b !important; }

.border-danger {
  border-color: #E11900 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #3a3f51 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.4rem !important; }

.rounded-lg {
  border-radius: 1rem !important;
  overflow: hidden;}

.rounded-top {
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important; }

.rounded-right {
  border-top-right-radius: 0.4rem !important;
  border-bottom-right-radius: 0.4rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.4rem !important;
  border-bottom-left-radius: 0.4rem !important; }

.rounded-left {
  border-top-left-radius: 0.4rem !important;
  border-bottom-left-radius: 0.4rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }
.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}
b,
strong {
  font-weight: bolder; }

.text-weight700 {
  font-weight: 700 !important;
}
small {
  font-size: 80%; }

.text-blue {
  color: #2C488A !important;
}
.text-primary {
  color: #3E7DEF !important; }

.text-success {
  color: #27c24c !important; }

.text-info {
  color: #23b7e5 !important; }

.text-warning {
  color: #ff902b !important; }

.text-danger {
  color: #f05050 !important; }

.text-white {
  color: #fff !important; }

.text-inverse {
  color: #131e26 !important; }

.text-alpha {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-pink {
  color: #f532e5 !important; }

.text-purple {
  color: #7266ba !important; }

.text-dark {
  color: #3a3f51 !important; }

.text-alpha-inverse {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-green {
  color: #37bc9b !important; }

.text-yellow {
  color: #fad732 !important; }

.text-gray-darker {
  color: #232735 !important; }

.text-gray-dark {
  color: #757575 !important; }

.text-gray {
  color: #dde6e9 !important; }

.text-gray-light {
  color: #e4eaec !important; }

.text-gray-lighter {
  color: #edf1f2 !important; }

.text-inherit {
  color: inherit !important; }

.text-sm {
  font-size: 0.74375rem; }

.text-md {
  font-size: 1.6625rem; }

.text-lg {
  font-size: 2.625rem; }

.text-nowrap {
  white-space: nowrap; }

.text-thin {
  font-weight: 100 !important; }

.text-normal {
  font-weight: normal !important; }

.text-bold {
  font-weight: bold !important; }

.inline {
  display: inline-block !important; }

.block-center {
  margin: 0 auto; }

.bg-primary {
  background-color: #5d9cec;
  color: #fff !important; }

.bg-primary-light {
  background-color: #8bb8f1;
  color: #fff !important; }

.bg-primary-dark {
  background-color: #2f80e7;
  color: #fff !important; }

.bg-primary small {
  color: inherit; }

.bg-success {
  background-color: #27c24c;
  color: #fff !important; }

.bg-success-light {
  background-color: #43d967;
  color: #fff !important; }

.bg-success-dark {
  background-color: #1e983b;
  color: #fff !important; }

.bg-success small {
  color: inherit; }

.bg-info {
  background-color: #23b7e5;
  color: #fff !important; }

.bg-info-light {
  background-color: #51c6ea;
  color: #fff !important; }

.bg-info-dark {
  background-color: #1797be;
  color: #fff !important; }

.bg-info small {
  color: inherit; }

.bg-warning {
  background-color: #ff902b;
  color: #fff !important; }

.bg-warning-light {
  background-color: #ffab5e;
  color: #fff !important; }

.bg-warning-dark {
  background-color: #f77600;
  color: #fff !important; }

.bg-warning small {
  color: inherit; }

.bg-danger {
  background-color: #f05050;
  color: #fff !important; }

.bg-danger-light {
  background-color: #f47f7f;
  color: #fff !important; }

.bg-danger-dark {
  background-color: #ec2121;
  color: #fff !important; }

.bg-danger small {
  color: inherit; }

.bg-green {
  background-color: #37bc9b;
  color: #fff !important; }

.bg-green-light {
  background-color: #58ceb1;
  color: #fff !important; }

.bg-green-dark {
  background-color: #2b957a;
  color: #fff !important; }

.bg-green small {
  color: inherit; }

.bg-pink {
  background-color: #f532e5;
  color: #fff !important; }

.bg-pink-light {
  background-color: #f763eb;
  color: #fff !important; }

.bg-pink-dark {
  background-color: #e90bd6;
  color: #fff !important; }

.bg-pink small {
  color: inherit; }

.bg-purple {
  background-color: #7266ba;
  color: #fff !important; }

.bg-purple-light {
  background-color: #9289ca;
  color: #fff !important; }

.bg-purple-dark {
  background-color: #564aa3;
  color: #fff !important; }

.bg-purple small {
  color: inherit; }

.bg-inverse {
  background-color: #131e26;
  color: #fff !important; }

.bg-inverse-light {
  background-color: #243948;
  color: #fff !important; }

.bg-inverse-dark {
  background-color: #020304;
  color: #fff !important; }

.bg-inverse small {
  color: inherit; }

.bg-yellow {
  background-color: #fad732;
  color: #fff !important; }

.bg-yellow-light {
  background-color: #fbe164;
  color: #fff !important; }

.bg-yellow-dark {
  background-color: #f3ca06;
  color: #fff !important; }

.bg-yellow small {
  color: inherit; }

.bg-white {
  background-color: #fff;
  color: inherit !important; }

.bg-gray-darker {
  background-color: #232735;
  color: #fff !important; }

.bg-gray-dark {
  background-color: #3a3f51;
  color: #fff !important; }

.bg-gray {
  background-color: #dde6e9;
  color: #656565 !important; }

.bg-gray-light {
  background-color: #e4eaec;
  color: #656565 !important; }

.bg-gray-lighter {
  background-color: #edf1f2;
  color: #656565 !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-cover {
  background-size: cover; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.hide {
  opacity:0;
}
.line-height-sm {
  line-height: 20px;
}
.badge {
  padding: .1875rem .4375rem;
  font-size: .75rem;
  border-radius: .625rem !important;
}
.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .badge {
    transition: none
  }
}

a.badge:focus,
a.badge:hover {
  text-decoration: none
}

.badge:empty {
  display: none
}

.btn .badge {
  position: relative;
  top: -1px
}

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem
}

.badge-primary {
  color: #fff;
  background-color: #5d9cec
}

a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #2f80e7
}

a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(93, 156, 236, .5)
}

.badge-secondary {
  color: #212529;
  background-color: #fff
}

a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #212529;
  background-color: #e6e6e6
}

a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem hsla(0, 0%, 100%, .5)
}

.badge-success {
  color: #fff;
  background-color: #27c24c
}

a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e983b
}

a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(39, 194, 76, .5)
}

.badge-info {
  color: #fff;
  background-color: #23b7e5
}

a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #1797be
}

a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(35, 183, 229, .5)
}

.badge-warning {
  color: #212529;
  background-color: #ff902b
}

a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #f77600
}

a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(255, 144, 43, .5)
}

.badge-danger {
  color: #fff;
  background-color: #E11900
}

a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #ec2121
}

a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(240, 80, 80, .5)
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa
}

a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5
}

a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.badge-dark {
  color: #fff;
  background-color: #3a3f51
}

a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #252833
}

a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(58, 63, 81, .5)
}
.file-button::file-selector-button {
  width: 100px;
  height: 40px;
  opacity: 0;
  position: relative;
}

.file-button::before {
  position: absolute;
  content: "画像を選択";
  width: 100px;
  height: 40px;
  font-weight: bold;
  color: white;
  background: rgb(147, 147, 147);
  font-size: 14px;
  border-radius: 10em;
  text-align: center;
  align-items: center;
  display: grid;
  cursor: pointer;
}
.file-button {
  font-size: 10px;
}
.file-button::file-selector-button {
  font-weight: bold;
  font-size: 14px;
  border: 0;
  border-radius: 10em;
  padding: 8px 16px;
  text-align: center;
}
