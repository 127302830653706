.LaundryPackApp {
    font-family: "Hiragino Sans";
    overflow-x: hidden;
}
.App {
    margin: 0 auto 8rem;
}
.Account {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Address {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Baisyo {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Cleaning {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Company {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Confirm {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Credit {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Home {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Location {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Location2 {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Notfound {
    padding: 0 1.5rem;
    max-width: 550px;
    margin: auto;
}
.Option {
    padding: 0 1.5rem;
    max-width: 550px;
    margin: auto;
}
.Order {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Orders {
    padding: 0 1.5rem;
    max-width: 550px;
    margin: auto;
}
.Other {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Prepare {
    padding: 0 1.5rem;
    max-width: 550px;
    margin: auto;
}
.Privacy {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Profile {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Register {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    /* margin: auto; */
}
.Rule {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Tokutei {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.ItemImage {
    max-width: 100%;
    height: auto;
    width: 100%;
}
.Leave {
    /* padding: 0 1.5rem; */
    max-width: 550px;
    margin: auto;
}
.Map {
    max-width: 100%;
    height: auto;
    width: 100%;
}
.form-control {
    max-width: 100%;
    display: block;
    width: 100%;
    padding: 0.375rem 1rem;
    font-size: 1rem;
    line-height: 2.5;
    color: #0E1D11;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dde6e9;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-sizing: border-box;
    font-weight:500;
    /* text-align: center; */
}
.form-control::placeholder{
    color: #6f7276;
}
.toolbar {
    font-weight: 400;
    box-sizing: border-box;
    overflow: hidden;
    word-spacing: 0;
    padding: 10px 10px 0px;
    margin: 0;
    border: none;
    line-height: normal;
    cursor: default;
    user-select: none;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: auto;
    background: #fff;
    color: #1f1f21;
    box-shadow: none;
    width: 100%;
    display:table;
}
.toolbar_left {
    box-sizing: border-box;
    background-clip: padding-box;
    padding: 10px 0 0 0;
    margin: 0;
    font: inherit;
    color: inherit;
    background: transparent;
    border: none;
    line-height: normal;
    max-width: 50%;
    width: 12%;
    text-align: left;
    line-height: 44px;
    font-size:1.5rem;
    display:table-cell;
    vertical-align: middle;
}
.toolbar_center {box-sizing: border-box;
    background-clip: padding-box;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    background: transparent;
    border: none;
    line-height: normal;
    width: 46%;
    text-align: center;
    line-height: 44px;
    font-size: 17px;
    font-weight: 500;
    color: #1f1f21;
    line-height: 44px;
    font-size: 17px;
    font-weight: 500;
    color: #1f1f21;
    margin: 0;
    padding: 0;
    overflow: visible;
    display:table-cell;
    vertical-align: middle;
}
.toolbar_right {
    box-sizing: border-box;
    background-clip: padding-box;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    background: transparent;
    border: none;
    line-height: normal;
    max-width: 50%;
    width: 12%;
    text-align: right;
    line-height: 44px;
    display: table-cell;
    vertical-align: middle;
}
.Lp .title{
    font-size: 32px;
    font-weight: 600;
}
.Confirm .title {
    background-color: #F2F2F2;
    padding: 1.5rem 1rem 0.7rem;
}
.Confirm .content {
    padding: 0.7rem 1rem;
}
.Confirm .explain {
    background-color: #F2F2F2;
    padding: 0.7rem 1rem;
}
.swiper {
    border: none;
    padding: 20px 40px !important;
}
.swiper_div {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3), 0 0 50px rgba(128, 128, 128, 0.1);
    border-color: transparent;
    height: 31rem;
}
.swiper_success{
    opacity:0.5;
}
.swiper_div2 {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3), 0 0 50px rgba(128, 128, 128, 0.1);
    border-color: transparent;
}
.swiper-pagination-bullet-active {
    background-color: #F8E100 !important;
}
.swiper-pagination{
    position: static !important;
}
.tabbar {
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
}
.menu_list{
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
}
.menu {
    color: #9C9C9C;
    width: 100%;
    height: 55px;
    padding: 1rem;
    font-weight: 600;
}
.menu.active {
    color: #3E7DEF;
}
.menu:active {
    opacity: 0.5;
    background-color: #9C9C9C;
    color: #fff;
}
.menu_icon{
    margin: -15px 0 -15px;
}
.menu_icon2 {
    margin: -13px 0 -15px;
}
.menu_orders{
    margin-top:4px;
}
.confirmMap {
    height:50vw;
}
.Home .home_header {
    padding: 1rem 25px 0.6rem;
    background-color: #F8E100;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1),
        0 0 50px rgba(128, 128, 128, 0.1);
}
.Home .home_contents {
    padding: 0 1.5rem;
}
.Home .flex_div {
    position: fixed;
    width: 100%;
    bottom: 55px;
    z-index: 101;
    background-color: white;
    padding: 10px;
    box-shadow: 0px -2px 3px -3px rgba(0, 0, 0, 0.3);
    max-width: 550px;
}
.Home .flex_div2 {
    position: fixed;
    width: 100%;
    bottom: 50px;z-index: 101;
    background-color: white;
    padding: 10px;
    box-shadow: 0px -2px 3px -3px rgba(0, 0, 0, 0.3);
    max-width: 550px;
}
.home_title {
    line-height: 22px;
    font-size: 18px;
}
.order {
    border-bottom: 1px solid #e0e0e0;
    display: table;
}
.order_body {
    padding: 0 1.5rem;
}
.order_content {
    display: table-cell;
    width: 100%;
}
.order_arrow {
    display: table-cell;
    vertical-align: middle;
    color: #757575;
    font-size: 1.3rem;
}
.order_status {
    display: inline-block;
}
.order_date {
    display: inline-block;
    color:#757575;
}
.order_item {
    font-size: 1.4rem;
}
.order_user_info {
    display:table;
    border-collapse: separate;
    border-spacing: 5px 0;
}
.order_pin {
    display:table-cell;
    vertical-align: middle;
    color: #757575;
}
.order_address {
    display:table-cell;
    color: #757575;
}
.account_list {
    border-top: 1px solid #e0e0e0;
    width: 100%;
}
.account {
    border-bottom: 1px solid #e0e0e0;
    display: inline-block;
    padding: 1.2rem 0 1rem;
    width: 100%;
}
.account_icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.3rem;
    padding: 0 2rem 0 2rem;
    width: 30%;
}
.account_content {
    display: inline-block;
    width: 60%;
}
.account_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #757575;
    font-size: 1.3rem;
    padding: 0 1rem 0 0;
    width: 10%;
}
.other_list {
    border-top: 1px solid #e0e0e0;
}
.other {
    border-bottom: 1px solid #e0e0e0;
    display: inline-block;
    padding: 1.2rem 0;
    width: 100%;
}
.other_content {
    padding: 0 2rem 0 2rem;
    display: inline-block;
    width: 90%;
}
.other_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #757575;
    font-size: 1.3rem;
    padding: 0 1rem 0 0;
    width: 10%;
}
.modal {
    background-color: #fff;
    margin: 1rem;
}
.modal_table{
    display: table;
}
.modal_icon {
    display: table-cell;
    vertical-align: middle;
    padding:0 1rem;
}
.modal_cell {
    display: table-cell;
    vertical-align: middle;
}
.Lp .App {
    text-align: center;
    font-family: "Hiragino Sans"
}

.Lp .Header {
    background-color: #F8E100;
    display: block;
    border: 0;
    padding: 10px;
    min-height: 40px;
}

.Lp .Header-logo {
    padding: 10px 15px;
    text-align: center;
}

.Lp .Header-logo-image {
    max-width: 270px;
    height: auto;
}

.Lp .Body {
    padding: 30px 10px;
    max-width: 550px;
    margin: 0 auto;
}

.Lp .Top {
    margin-bottom: 30px;
}

.Lp .Image {
    max-width: 100%;
    height: auto;
    width: 100%;
}

.Lp .form-control {
    max-width: 100%;
    display: block;
    width: 100%;
    padding: 0.375rem 1rem;
    font-size: 1rem;
    line-height: 2.5;
    color: #0E1D11;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dde6e9;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 20px;
    /* text-align: center; */
}

.Lp .form-control::placeholder {
    color: #495057;
}

.Lp .Postcode {
    width: 100%;
    max-width: 370px !important;
    display: inline-block;
}

.Lp .Postcode-button {
    max-width: 100%;
    background-color: #F8E100;
}

.Lp .Postcode-button:hover {
    background-color: #f8df00e7;
}

.Lp .Postcode-button:hover:active {
    background-color: #f8df00e7;
}

.Lp .Footer {
    color: #fff;
    background-color: #0E1D11;
    display: block;
    border: 0;
    padding: 10px;
    min-height: 40px;
    text-align: center;
}
.Lp .Footer .content{
    vertical-align: middle;
}
.Lp .Footer .link {
    display:inline-block;
    font-size: 10px;
    padding: 0 10px;
    border-right: #fff 1px solid;
}
.Lp .Footer .link2 {
    display: inline-block;
    font-size: 10px;
    padding: 0 10px;
}
.Lp .Footer-link {
    color: #fff;
    text-decoration: none;
    font-weight: 300;
}
.Register .App-step0 {
    text-align: center;
    height: 100vh;
    width: 100vw;
    margin-top: 0px;
    background-image: url(../../public/img/step0.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.Register .ud_img {
    height: 3rem;
}

.Register .App-step1 {
    text-align: center;
    height: 100vh;
    width: 100vw;
    margin-top: 0px;
    background-color: #F8E100;
}

.Register .App-h1 {
    color: var(--mono-gray---087, #0E1D11);
    text-align: center;
    text-shadow: 0px 0px 8px #FFF;

    /* Hiragino Sans/1.2/w7/40px */
    font-family: "Hiragino Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 48px */
    white-space: pre-wrap;
}

.Register .App-h2 {
    margin-top: 10px;
    color: var(--mono-gray---087, #0E1D11);
    text-align: center;
    text-shadow: 0px 0px 8px #FFF;
    font-family: "Hiragino Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    /* 23.4px */
}

.Register .App-h3 {
    color: var(--mono-gray---003, #FFF);
    text-align: center;
    align-items: center;
    width: 100vw;
    /* Hiragino Sans/1.5/w3/14px */
    font-family: "Hiragino Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    /* 21px */
    position: absolute;
    bottom: 15px;
    white-space: pre-wrap;
}

.Register .App-inline {
    margin-top: 35px;
    gap: 16px;
    text-align: center;
}

.Register .App-title {
    margin-top: 35px;
    color: var(--mono-gray---087, #0E1D11);
    text-align: center;

    /* Hiragino Sans/1.5/w6/22px */
    font-family: "Hiragino Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 33px */
}
.Register .App-title-sm {
    margin-top: 10px;
    color: var(--mono-gray---087, #0E1D11);
    text-align: center;

    /* Hiragino Sans/1.5/w6/22px */
    font-family: "Hiragino Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 33px */
}

.Register .App-subtitle {
    color: var(--mono-gray---087, #0E1D11);
    text-align: center;

    /* Hiragino Sans/1.0/w3/16px */
    font-family: "Hiragino Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    /* 16px */
}

.Register .App-comment {
    color: var(--mono-gray---087, #0E1D11);
    text-align: center;
    padding-top: 16px;
    padding-left: 28px;
    padding-right: 28px;
    /* Hiragino Sans/1.3/w3/14px */
    font-family: "Hiragino Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    /* 18.2px */
    white-space: pre-wrap;
}

.Add-comment {
    text-align: center;
    padding-top: 4px;
    padding-left: 28px;
    padding-right: 28px;
    margin-bottom: 15px;
    color: var(--mono-gray---087, #0E1D11);
    text-align: center;
    font-family: "Hiragino Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    /* 12px */
    white-space: pre-wrap;
}

.Register .Map-comment {
    color: var(--mono-gray---054, #757575);

    /* Hiragino Sans/1.3/w3/16px */
    font-family: "Hiragino Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    /* 20.8px */

    text-align: center;
    margin-top: 10px;
    padding-top: 16px;
    padding-left: 28px;
    padding-right: 28px;
    white-space: pre-wrap;
}

.Register .App-tel {
    padding-top: 50px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.Register .App-map {
    width: 100%;
    height: 400px;
}

.Register .Input-view {
    margin-top: 20px;
    margin-left: 14px;
    margin-right: 14px;
    text-align: left;
    gap: 16px;
    ;
}

.Register .Input-title {
    color: var(--mono-gray---054, #757575);

    /* Hiragino Sans/1.0/w6/14px */
    font-family: "Hiragino Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 14px */
    ;
}

.Register .Input-require {
    color: var(--ui-saffron--050, #C50018);

    /* Hiragino Sans/1.0/w6/14px */
    font-family: "Hiragino Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 14px */
}

.Register .tel-title {
    color: var(--mono-gray---087, #0E1D11);
    text-align: center;

    /* Hiragino Sans/1.3/w6/28px */
    font-family: "Hiragino Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 36.4px */
    white-space: pre-wrap;
}

.Register .App-telno {
    padding-top: 24px;
    padding-inline-start: 24px;
    padding-inline-end: 24px;
    padding-bottom: 135px;
}

.Register .button-next {
    margin-top: 10px;
    width: 327px;
    height: 50px;
    border-radius: 47px;
    border-color: #F8E100;
    background: var(--brand-yellow---050, #F8E100);
    color: var(--mono-gray---087, #0E1D11);
    text-align: center;
    /* Hiragino Sans/1.0/w6/16px */
    font-family: "Hiragino Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 16px */
}

.Register .button-next-w {
    margin-top: 10px;
    width: 327px;
    height: 50px;
    border-radius: 47px;
    border-color: #FFF;
    background: var(--mono-gray---003, #FFF);
    color: var(--mono-gray---087, #0E1D11);
    text-align: center;
    /* Hiragino Sans/1.0/w6/16px */
    font-family: "Hiragino Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 16px */
}

.Register .button-next-g {
    margin-top: 10px;
    width: 327px;
    height: 50px;
    border-radius: 47px;
    border-color: #E9E9E9;
    background: var(--mono-gray---003, #E9E9E9);
    color: var(--mono-gray---087, #AEAEAE);
    text-align: center;
    /* Hiragino Sans/1.0/w6/16px */
    font-family: "Hiragino Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 16px */
}

.Register .App-img {
    margin-top: 16px;
    width: 255px;
    height: 255px;
    flex-shrink: 0;
}

.Register .App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .Register .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.Register .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Register .App-link {
    color: #61dafb;
}
#payjp_checkout_box input[type=button]{
    color: #0E1D11 !important;
    background-color: #ffffff !important;
    border-color: #000000 !important;
    background-image: linear-gradient(#ffffff 0, #ffffff 48%, #ffffff 100%) !important;
    border-radius: 50px !important;
    text-transform: none !important;
    overflow: visible !important;
    font-size: 13px !important;
    outline: none !important;
    transition: all 0.1s !important;
    display: inline-block !important;
    font-weight: 600 !important;
    text-align: center !important;
    white-space: nowrap !important;
    vertical-align: middle !important;
    -webkit-user-select: none !important;
    user-select: none !important;
    border: 1px solid !important;
    padding: 1rem 3rem !important;
    font-size: 1rem !important;
    line-height: 1.52857 !important;
    max-width: 100% !important;
    width: auto !important;
}
.Toastify__toast {
    margin: 20px !important;
}
.Toastify__close-button {
    display: none !important;
}
.Toastify__toast-body {
    padding:3px !important;
    text-align:center;
}
.Toastify__toast{
    min-height: 4px !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.css-fUjKRK{
    justify-content: space-around;
}
.link_deco_none{
    text-decoration: none;
}
.register-slide-label {
    padding: 5px 8px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
    border: 1px solid transparent;
    display: block;
    background-color: #C50018;
    color: white;
    font-family: 'Futura';
}
.baseDialog,
.menuDialog {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    background-color: transparent;
    border: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overscroll-behavior: none;
}

.baseDialog_wrapper {
    width: 100%;
    height: calc(100% + 1px);
    display: flex;
    padding: 24px;
    justify-content: center;
    align-items: center;
}
.baseDialog_content {
    width: 100%;
    height: 94vh;
    overflow: hidden;
    border-radius: 8px;
    background-color: #fff;
}
.baseDialog_content_inner {
    width: 100%;
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
}
.creditIcon{
    width: 20%;
}
